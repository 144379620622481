<!-- System: STA
    Purpose: This compoment will display edit
            company modal. where user update 
            name and team size
-->

<template>
  <!-- Edit Company Modal -->
  <v-dialog v-model="EditCompanyModal" width="500" @click:outside="onHidden()">
    <v-card id="updateModalAddOrg" ref="updateModalAddOrg" title="Edit Company">
      <v-card-title class="headline white lighten-2 justify-center">
        Edit Company
        <v-spacer></v-spacer>
        <v-btn icon @click="(EditCompanyModal = false), onHidden()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card-title>
      <v-divider></v-divider>
      <!-- Edit Company Form -->
      <v-form @submit.prevent="onSubmit" id="updateCompany" v-model="valid">
        <div style="padding-right: 30px; padding-left: 30px" class="col-12">
          <!-- Edit Image -->
          <div class="text-center cursor-poniter file-upload" v-if="!image">
            <label class="fileContainer">
              <div>
                <img src="../../assets/images/dashboard_icons/cloud_icon.svg" />
              </div>
              <div class="upload-text">
                Upload an image upto the size of 1MB<br />
                Dimensions 174 x 75
              </div>
              <input
                @change="onFileChange"
                accept="image/png,image/jpg,image/jpeg"
                name="file_name"
                type="file"
                height="100"
                width="100"
              />
            </label>
          </div>
          <div class="mt-3 text-center" v-else>
            <imageCroper
              :cropHeight="75"
              :cropWidth="174"
              :imgSrc="image"
              :imgStyle="{ width: 'auto', height: 'auto' }"
              :resizeable="true"
              @croped_file="attach_crop_file($event)"
              alt="Company Image"
              v-if="!cropped_file_url && is_cropper"
            />
            <img
              :src="image"
              class="mb-2 mt-n3 img-thumbnail"
              v-if="!cropped_file_url && !is_cropper"
              height="100"
              width="100"
            />
            <img
              :src="cropped_file_url"
              class="mb-2 img-thumbnail"
              v-if="cropped_file_url && is_cropper"
              height="100"
              width="100"
            />
            <br />
            <v-btn
              :class="{ 'my-margin': cropped_file_url === '' }"
              @click="removeImage"
              class="text-right mt-3"
              color="primary"
              outlined
              type="button"
              v-if="image"
              small
            >
              Remove image
            </v-btn>
          </div>
        </div>
        <div style="padding: 15px" class="my-create-project c-project-form">
          <!-- Edit Company Name -->
          <v-item-group class="col-md-12 mt-n2 mb-n2">
            <v-text-field
              outlined
              class="mt-n2"
              data-vv-as="Company Name"
              name="company_name"
              autocomplete="off"
              label="Company Name"
              type="text"
              v-model="orgForm.name"
              :rules="nameRules"
            >
            </v-text-field>
          </v-item-group>
          <v-item-group class="col-md-12">
            <!-- Edit Company Size -->
            <v-text-field
              outlined
              class="mt-n5"
              data-vv-as="Team Size"
              autocomplete="off"
              name="team_size"
              label="Team Size"
              type="text"
              v-model="orgForm.team_size"
              :rules="sizeRules"
            >
            </v-text-field>
          </v-item-group>
          <!-- Update Button -->
          <div class="col-12 mt-n5 mb-n2">
            <v-btn
              :disabled="!valid || updateCompanyLoader"
              class="create-project-button"
              color="primary"
              ref="submit"
              type="submit"
            >
              <v-progress-circular
                indeterminate
                v-if="updateCompanyLoader"
                size="20"
              >
              </v-progress-circular>
              Update Company
            </v-btn>
          </div>
        </div>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { baseURL } from "@/assets/js/urls";
import { companyNameRules, TeamSize } from "@/Constants/Const";
export default {
  name: "edit-company-modal",
  components: {
    imageCroper: () => import("@/components/includes/helper/ImageCropper"),
  },
  data() {
    return {
      EditCompanyModal: false,
      valid: true,
      updateCompanyLoader: false,
      remove_image: false,
      orgForm: {
        name: "",
        team_size: "",
        logo: "",
        id: "",
      },
      url: null,
      is_picture: false,
      picturePath: "",
      status: false,
      image: "",
      companyData: [],
      is_cropped: false,
      is_cropper: false,
      cropped_file_url: "",
      name_error: "",
      nameRules: companyNameRules,
      sizeRules: [
        (v) => !!v || "Required",
        (v) => /^[0-9 ]*$/.test(v) || "Only numbers are allowed",
      ],
    };
  },
  mounted() {
    this.$root.$on("company_data", (data) => {
      this.EditCompanyModal = true;
      this.orgForm = Object.assign({}, data);
      this.image = data.logo ? baseURL.API_URL + "storage/" + data.logo : "";
    });
  },
  methods: {
    /**
     * This function is resposible for
     * submitting the updated edit company
     * form (api call)
     */
    onSubmit(evt) {
      evt.preventDefault();
      let formData = new FormData(document.getElementById("updateCompany"));
      formData.append("logo", this.picturePath);
      formData.append("status", this.status);
      formData.append("company_id", this.orgForm.id);
      formData.append("name", formData.get("company_name"));
      formData.append("remove_image", this.remove_image);
      this.$store.commit("custom/toggle_loader", true);
      this.updateCompanyLoader = true;
      this.$store
        .dispatch("custom/updateCompany", formData)
        .then((response) => {
          this.updateCompanyLoader = false;
          this.EditCompanyModal = false;
          this.$store.commit("custom/toggle_loader", false);
          if (response.data.error === false) {
            this.status = false;
            this.$store.dispatch("custom/getAllCompaniesList");
            this.$root.$emit("snack_bar", {
              show: true,
              message: "Your record is updated successfully!",
              snackbarColor: "green",
            });
          }
          Object.assign(this.$data, this.$options.data.call(this));
        })
        .catch((error) => {
          this.updateCompanyLoader = false;
          this.$store.commit("custom/toggle_loader", false);
          if (error.response.data.errors) {
            if (error.response.data.errors.name) {
              this.$root.$emit("snack_bar", {
                show: true,
                message: error.response.data.errors.name,
                snackbarColor: "red",
              });
            }
            if (error.response.data.errors.team_size) {
              this.$root.$emit("snack_bar", {
                show: true,
                message: error.response.data.errors.team_size[0],
                snackbarColor: "red",
              });
            }
          }
        });
    },
    /**
     * These function is resposible for
     * changing , updating of comapny image
     */
    onFileChange(e) {
      var files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createImage(file) {
      let _size = file.size;
      if (_size > 1000000) {
        return;
      }
      var reader = new FileReader();
      var vm = this;

      reader.onload = (e) => {
        vm.image = e.target.result;
        this.remove_image = true;
      };
      this.picturePath = file;
      reader.readAsDataURL(file);

      this.is_cropper = true;
    },
    removeImage: function () {
      this.status = true;
      this.image = "";
      this.picturePath = "";
      this.cropped_file_url = "";
      this.is_cropped = false;
      this.remove_image = true;
    },
    attach_crop_file(file_url) {
      let file_path = this.dataURLtoFile(file_url, "project_logo");
      this.cropped_file_url = file_url;
      this.picturePath = file_path;
      this.is_cropped = true;
    },
    dataURLtoFile(dataurl, filename) {
      let arr = dataurl.split(","),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new File([u8arr], filename, { type: mime });
    },
    onHidden() {
      this.is_cropper = false;
      this.cropped_file_url = "";
    },
  },
};
</script>

<style scoped>
.file-upload {
  border: dashed 2px #cbdaf2;
  cursor: pointer !important;
  background-color: rgba(240, 248, 255, 0.93);
  box-shadow: 0 2px 4px 0 rgba(227, 227, 227, 0.45);
  color: #a2b7d9;
  font-family: "Poppins", "sans-serif";
  letter-spacing: 0.15px;
  line-height: 18px;
  text-align: center;
  padding-top: 10px;
}

.fileContainer {
  overflow: hidden;
  position: relative;
  cursor: pointer !important;
  font-size: 11px;
  height: 92px;
  border-radius: 3px;
  background-color: rgba(240, 248, 255, 0.93);
  box-shadow: 0 2px 4px 0 rgba(227, 227, 227, 0.45);
  color: #a2b7d9;
  font-family: "Poppins", "sans-serif";
  letter-spacing: 0.15px;
  line-height: 18px;
  text-align: center;
  padding-top: 10px;
}

.fileContainer [type="file"] {
  display: block;
  font-size: 999px;
  filter: alpha(opacity=0);
  min-height: 100%;
  min-width: 100%;
  opacity: 0;
  position: absolute;
  right: 0;
  text-align: right;
  top: 0;
  cursor: pointer;
}

.fileContainer img {
  height: 32px;
  width: 32px;
}
</style>
